import { template as template_7f5d0d2a202042edbad4f99087dbcf07 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7f5d0d2a202042edbad4f99087dbcf07(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
