import { template as template_c14616475a5a4b0190c885852f5ca068 } from "@ember/template-compiler";
const FKText = template_c14616475a5a4b0190c885852f5ca068(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
