import { template as template_1144f78a5afe44d4a4729aa94dc03b9c } from "@ember/template-compiler";
const SidebarSectionMessage = template_1144f78a5afe44d4a4729aa94dc03b9c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
