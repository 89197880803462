import { template as template_4261b187b59d4037b9284a564e618453 } from "@ember/template-compiler";
const WelcomeHeader = template_4261b187b59d4037b9284a564e618453(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
